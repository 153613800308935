import { z } from 'zod';

/** Location dto schema. */
export const locationDtoSchema = z.object({
	id: z.number(),
	name: z.string(),
	loc_id: z.string(),
	can_quote: z.boolean(),
	prefix: z.string(),
});

/** Location dto. */
export type LocationDto = Readonly<z.infer<typeof locationDtoSchema>>;

/** Location filter params dto. */
export type LocationFilterParamsDto = {

	/** Name. */
	readonly name?: string;

	/** Whether location is in equipment manager. */
	readonly in_equipment_manager?: boolean;

	/** Whether location is in storage. */
	readonly in_storage?: boolean;

	/** Whether location is in job scheduler/djb */
	readonly in_jobs?: boolean;

	/** Page. */
	readonly page?: number;

	/** Per page. */
	readonly per_page?: number;
};
